import React, {useContext,useRef} from 'react'
import Resume from './Resume'
import Navbar from './Navbar'
import Accordian from './Accordian'
import FormContext from "../context/form/formContext"
import { useReactToPrint } from 'react-to-print';



export default function Home() {

  const formContext = useContext(FormContext)
  const componentRef = useRef();
  const onClickDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  const title1 = formContext.resume.name ?? "RESUME";
  const title2 = "_";
  const title3 = formContext.resume.role ?? "MAKER";

  document.title = title1+title2+title3;
    
  return (
    <>
    <div className="row p-0 m-0" style={{width:"100vw", minWidth:"915px",  fontFamily:"Verdana"}}>
      <div className="col-7">
        <Navbar onClickDownload={onClickDownload}/>
        <div className="row p-0 m-0 mt-3" style={{textAlign: "center"}}>
          <div className="strong m-auto">
            R E S U M E&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;D E T A I L S&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;F O R M
          </div>
        </div>
        <div>
          <Accordian/>
        </div>
      </div>
      <div className="col-5 p-0" id="resume-container">
        <Resume data={formContext.resume} ref={componentRef}/>
      </div>
    </div>
    </>
  );
}
