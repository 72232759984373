import React, { forwardRef, useEffect, useState } from 'react'
import DeviderForResume from './DeviderForResume'
import "../App.css"


const Resume = forwardRef(function MyInput(props, ref) {
  const [resumeHeight, setResumeHeight] = useState();
  useEffect(() => {
    // console.log(document.getElementById('resume-container').clientHeight)
    setResumeHeight(Math.ceil(document.getElementById('resume-container').clientHeight / 1123) * 1123)
  }, [props])
  return (
    <div className="print-container" style={{ color: props.data.REACT_APP_COLOR_OVER_ALL, fontSize: props.data.REACT_APP_FONT_SIZE_OVER_ALL + "px", position: 'relative' }} ref={ref}>
      <div className='row gx-0' style={{ marginLeft: 0 }} >
        <div className="col-4 p-0" style={{ height: resumeHeight, padding: '12px', backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, position: 'absolute', zIndex: "0" }}></div>
      </div>

      <div className="row p-0 m-0 GLOBAL_DIV" id="resume-pdf" style={{ zIndex: 1, position: 'relative' }}>
        {/* top section */}
        <div className="row p-0 m-0">
          <div className="col-4" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR }}>
          </div>
          <div className="col-8" style={{ backgroundColor: '#ffffff' }}>
            <div className="card border-0 mt-4">
              <h1 style={{ fontSize: props.data.REACT_APP_FONT_SIZE_NAME + 'px', color: props.data.REACT_APP_COLOR_NAME, fontFamily: "Tahoma" }}>
                <strong>{(props.data.name) ? props.data.name : "----- --"}</strong> <strong style={{ fontSize: props.data.REACT_APP_FONT_SIZE_OVER_ALL + 'px', color: props.data.REACT_APP_COLOR_OVER_ALL }}>({(props.data.experience) ? props.data.experience : 0} Years Experience)</strong>
              </h1>
              <p style={{ color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>
                <strong style={{ fontSize: props.data.REACT_APP_FONT_SIZE_HEADING + 'px' }}>{(props.data.role) ? props.data.role : "-----"} ({(props.data.roleType) ? props.data.roleType : "-----"})</strong>
              </p>
            </div>
          </div>
        </div>
        {/* Employment Header */}
        {props.data.employement.length ? <div className="row p-0 m-0">
          <div className="col-4 pb-2" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <strong className="text-light" style={{ fontFamily: "Tahoma" }}>
              E M P L O Y M E N T
            </strong>
          </div>
          <div className="col-8" style={{ backgroundColor: '#ffffff' }}>
          </div>
        </div> : ""}
        {/* divider */}
        {props.data.employement.length ? <DeviderForResume color={props.data.REACT_APP_COLOR_OVER_SIDEBAR} /> : ""}
        {/* Employment Details */}
        {props.data.employement.length ? props.data.employement.map((value, index) => <div key={index} className="row p-0 m-0">
          <div className="col-4 pt-3" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <p className="text-light" style={{ fontSize: props.data.REACT_APP_FONT_SIZE_OVER_ALL + 'px' }}>{(value.from) ? value.from : ""} - {(value.to) ? value.to : ""}</p>
          </div>
          <div className="col-8 pt-3" style={{ backgroundColor: '#ffffff' }}>
            <div className="card border-0">
              <nobr><strong style={{ color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>{(value.company) ? value.company : "-------"}</strong> {(value.slogan) ? value.slogan : ""}</nobr>
              <div className="card ps-4 border-0 pe-4" dangerouslySetInnerHTML={{ __html: value.bullets }}>

              </div>
            </div>
          </div>
        </div>) : ""}
        {/* Projects Header */}
        {props.data.project.length ? <div className="row p-0 m-0">
          <div className="col-4 pb-2" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <strong className="text-light" style={{ fontFamily: "Tahoma" }}>
              P R O J E C T S
            </strong>
          </div>
          <div className="col-8" style={{ backgroundColor: '#ffffff' }}>
          </div>
        </div> : ""}
        {/* divider */}
        {props.data.project.length ? <DeviderForResume color={props.data.REACT_APP_COLOR_OVER_SIDEBAR} /> : ""}
        {/* Projects Details */}
        {props.data.project.length ? props.data.project.map((value, index) => <div key={index} className="row p-0 m-0">
          <div className="col-4 pt-3" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <p className="text-light" style={{ fontSize: props.data.REACT_APP_FONT_SIZE_OVER_ALL + 'px' }}>{(value.from) ? value.from : ""} - {value.to ?? ""}</p>
          </div>
          <div className="col-8 pt-3" style={{ backgroundColor: '#ffffff' }}>
            <div className="card border-0">
              <nobr><strong style={{ color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>{value.title ?? "-------- --- --"}</strong></nobr>
              <p className="p-0 m-0">Role : {value.role ?? "---- ---"}</p>
              {value.bullets ? <p className="p-0 m-0">Responsiblities : </p> : ""}
              <div className="card border-0" dangerouslySetInnerHTML={{ __html: value.bullets }}>
              </div>
              <nobr>{value.tech && <>
                <ul>
                  <li>
                  Technologies : {value.tech}
                  </li>
                </ul>
              </>}</nobr>
            </div>
          </div>
        </div>) : ""}
        {/* Skills Header */}
        {props.data.skills.length || props.data.skillsw.length ? <div className="row p-0 m-0">
          <div className="col-4 pb-2" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <strong className="text-light" style={{ fontFamily: "Tahoma" }}>
              S K I L L S
            </strong>
          </div>
          <div className="col-8" style={{ backgroundColor: '#ffffff' }}>
          </div>
        </div> : ""}
        {/* divider */}
        {props.data.skills.length || props.data.skillsw.length ? <DeviderForResume color={props.data.REACT_APP_COLOR_OVER_SIDEBAR} /> : ""}
        {/* Skills Details */}
        {props.data.skills.length || props.data.skillsw.length ? <div className="row p-0 m-0">
          <div className="col-4 pt-3" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            {/* <p class="text-light" style="font-size: 10px;">2022 Jan - Present</p> */}
          </div>
          <div className="col-8 pt-3" style={{ backgroundColor: '#ffffff' }}>
            <div className="card border-0">
              {/* skill with % */}
              <div className="container m-0 p-0">
                {props.data.skills.map((value, index) => (
                  <div className="row" key={index}>
                    <div className="col-6" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}><strong>{value.skill ?? "---- --"}</strong></div>
                    <div className="col-6" style={{ textAlign: 'left' }}>-&nbsp;&nbsp;{value.percent ?? 0}%</div>
                  </div>
                ))}
              </div>
              {/* skill with % */}
              {/* skill without % */}
              <div className="container m-0 p-0">
                <div className="row">
                  {props.data.skillsw.map((value, index) => (
                    <div key={index} className="col-6" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}><strong>{value.skill ?? "---- --"}</strong></div>
                  ))}
                </div>
              </div>
              {/* skill without % */}
            </div>
          </div>
        </div> : ""}
        {/* Educations Header */}
        {props.data.education.length ? <div className="row p-0 m-0">
          <div className="col-4 pb-2" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <strong className="text-light" style={{ fontFamily: "Tahoma" }}>
              E D U C A T I O N
            </strong>
          </div>
          <div className="col-8" style={{ backgroundColor: '#ffffff' }}>
          </div>
        </div> : ""}
        {/* divider */}
        {props.data.education.length ? <DeviderForResume color={props.data.REACT_APP_COLOR_OVER_SIDEBAR} /> : ""}
        {/* Education Details */}
        {props.data.education.length ? props.data.education.map((value, index) => <div key={index} className="row p-0 m-0">
          <div className="col-4 pt-3" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <p className="text-light" style={{ fontSize: props.data.REACT_APP_FONT_SIZE_OVER_ALL + 'px' }}>{value.from ?? ""} - {value.to ?? ""}</p>
          </div>
          <div className="col-8 pt-3" style={{ backgroundColor: '#ffffff' }}>
            <div className="card border-0">
              <table className="table">
                <thead>
                  <tr>
                    <th className="border-0 p-0 m-0" style={{ color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>
                      <nobr>{value.collegeName ?? "----- -------- ---"}</nobr>
                      <br />
                      <nobr>{value.shortDegree ?? "-----"} - {value.largeDegree ?? "--------------------------"} {value.percent && <>({value.percent}%)</>}</nobr>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>) : ""}
        {/* Certification Header */}
        {props.data.certification.length ? <div className="row p-0 m-0">
          <div className="col-4 pb-2" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <strong className="text-light" style={{ fontFamily: "Tahoma" }}>
              C E R T I F I C A T I O N
            </strong>
          </div>
          <div className="col-8" style={{ backgroundColor: '#ffffff' }}>
          </div>
        </div> : ""}
        {/* divider */}
        {props.data.certification.length ? <DeviderForResume color={props.data.REACT_APP_COLOR_OVER_SIDEBAR} /> : ""}
        {/* Certification Details */}
        {props.data.certification.length ? props.data.certification.map((value, index) => <div key={index} className="row p-0 m-0">
          <div className="col-4 pt-3" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <p className="text-light" style={{ fontSize: props.data.REACT_APP_FONT_SIZE_OVER_ALL + 'px' }}>{value.from ?? ""} - {value.from ?? ""}</p>
          </div>
          <div className="col-8 pt-3" style={{ backgroundColor: '#ffffff' }}>
            <div className="card border-0">
              <table className="table">
                <tbody>
                  <tr>
                    <td className="border-0 p-0 m-0">
                      <strong style={{ color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>{value.heading ?? "---- "} </strong> {value.description ? ":" + value.description : ""}
                    </td>
                  </tr>
                </tbody></table>
            </div>
          </div>
        </div>) : ""}
        {/* Personl Header */}
        {props.data.father ? <div className="row p-0 m-0">
          <div className="col-4 pb-2" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <strong className="text-light" style={{ fontFamily: "Tahoma" }}>
              P E R S O N A L
            </strong>
          </div>
          <div className="col-8" style={{ backgroundColor: '#ffffff' }}>
          </div>
        </div> : ""}
        {/* divider */}
        {props.data.father ? <DeviderForResume color={props.data.REACT_APP_COLOR_OVER_SIDEBAR} /> : ""}
        {/* Personal Details */}
        {props.data.father ? <div className="row p-0 m-0">
          <div className="col-4 pt-3" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            {/* <p class="text-light" style="font-size: 10px;">2022 Jan - Present</p> */}
          </div>
          <div className="col-8 pt-3" style={{ backgroundColor: '#ffffff' }}>
            <div className="card border-0">
              <table className="table">
                <thead>
                  <tr>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>FATHER NAME</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', fontWeight: 450 }}>-</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', fontWeight: 450 }}>{props.data.father}</th>
                  </tr>
                  <tr>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>SEX</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', fontWeight: 450 }}>-</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', fontWeight: 450 }}>{props.data.sex}</th>
                  </tr>
                  <tr>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>LANGUAGE</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', fontWeight: 450 }}>-</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', fontWeight: 450 }}>{props.data.language}</th>
                  </tr>
                  <tr>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>MOBILE</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', fontWeight: 450 }}>-</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', fontWeight: 450 }}>{props.data.mobile}</th>
                  </tr>
                  <tr>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>EMAIL</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', fontWeight: 450 }}>-</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', fontWeight: 450 }}>{props.data.email}</th>
                  </tr>
                  <tr>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>NATIONALITY</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', fontWeight: 450 }}>-</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', fontWeight: 450 }}>{props.data.nationality}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div> : ""}
        {/* Declaration Header */}
        {props.data.date ? <div className="row p-0 m-0">
          <div className="col-4 pb-2" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            <strong className="text-light" style={{ fontFamily: "Tahoma" }}>
              D E C L A R E
            </strong>
          </div>
          <div className="col-8" style={{ backgroundColor: '#ffffff' }}>
          </div>
        </div> : ""}
        {/* divider */}
        {props.data.date ? <DeviderForResume color={props.data.REACT_APP_COLOR_OVER_SIDEBAR} /> : ""}
        {/* declaration Details */}
        {props.data.date ? <div className="row p-0 m-0">
          <div className="col-4 pt-3" style={{ backgroundColor: props.data.REACT_APP_COLOR_OVER_SIDEBAR, textAlign: 'center' }}>
            {/* <p class="text-light" style="font-size: 10px;">2022 Jan - Present</p> */}
          </div>
          <div className="col-8 pt-3" style={{ backgroundColor: '#ffffff' }}>
            <div className="card border-0">
              <table className="table">
                <thead>
                  <tr>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>DATE: {props.data.date}</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', fontWeight: 450 }}>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', fontWeight: 450 }}>{props.data.signature}</th>
                  </tr>
                  <tr>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'left', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>PLACE: {props.data.place}</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', fontWeight: 450 }}>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th className="border-0 p-0 m-0" style={{ textAlign: 'center', color: props.data.REACT_APP_COLOR_HEADING, fontFamily: "Tahoma" }}>(SIGNATURE)</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div> : ""}
      </div>
      <div className="page-break" style={{ position: 'relative' }} />

    </div>
  )
});

export default Resume
