import React, { useContext, useState } from 'react'
import FormContext from "../../context/form/formContext"
import Editor from 'react-simple-wysiwyg';


export default function Employement() {
    const initialData = {
        from: "",
        to: "",
        company: "",
        slogan: "",
        bullets: ""
    }

    const formContext = useContext(FormContext)

    const [data, setData] = useState(initialData)
    const [arr, setArr] = useState([])
    const [edit, setEdit] = useState(false)
    const [idx, setIdx] = useState(0)

    const handleOnChange = (val, key) => {
        setData({ ...data, [key]: val })
    }

    const addData = () => {
        if (!data.from || !data.to || !data.company) {
            alert("FROM, TO, and COMPANY NAME fields are required");
        } else {
            setArr([...arr, data]);
            setData(initialData);
            formContext.setResume(prevResume => ({ ...prevResume, "employement": [...prevResume.employement, data] }));
        }
    }

    const onDeleteHandler = (index, idx) => {
        if(edit && index === idx){
            alert("This data is in editing mode, cannot delete Please save or cancel first.")
        }else{
            const updatedData = [...arr];
            updatedData.splice(index, 1);
            setArr(updatedData);
            formContext.setResume(prevResume => ({ ...prevResume, "employement": updatedData }));
        }
    }

    const cancelData = () => {
        setEdit(false)
        setData(initialData)
    }

    const onUpdateHandler = (index) => {
        setEdit(true)
        setIdx(index)
        setData(arr[index])
    }

    const editData = (index) => {
        if (!data.from || !data.to || !data.company) {
            alert("FROM, TO, and COMPANY NAME fields are required");
        } else {
            const newArr = [...arr]; // Create a copy of the existing array
            newArr[index] = data; // Update the element at the specified index
            setArr(newArr); // Set the updated array
            console.log(newArr);
            setData(initialData);
            setEdit(false)
            formContext.setResume(prevResume => ({ ...prevResume, "employement": newArr }));
        }
    }

    return (
        <div>
            {/* Main Row */}
            <div className="row p-0 m-0">
                <div className="row p-0 m-0">
                    <div className="col-4" style={{ overflow: 'hidden' }}>
                        <ol className='p-0 m-0' style={{ listStyleType: "+  " }}>
                            {arr && arr.map((val, index) => <li key={index}><nobr style={{ fontSize: "12px" }}><span className="badge text-bg-danger p-2" onClick={() => onDeleteHandler(index, idx)} style={{ cursor: "pointer" }}><i className="bi bi-trash"></i></span>&nbsp;<span className="badge text-bg-dark p-2" onClick={() => onUpdateHandler(idx)} style={{ cursor: "pointer" }}><i className="bi bi-pencil"></i></span>&nbsp;&nbsp;&nbsp;&nbsp;<strong style={{ textOverflow: "ellipsis" }}>{val.company} ({val.to})</strong></nobr></li>)}
                        </ol>
                        {(arr.length) ? "" : <>N O <br />D A T A <br />F O U N D</>}
                    </div>
                    <div className="col-8 row">
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="text" autoComplete='OFF' className="form-control" id="floatingInput" value={data.from} onChange={e => handleOnChange(e.target.value, "from")} placeholder="FROM" />
                                <label htmlFor="floatingInput">FROM</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="text" autoComplete='OFF' className="form-control" id="floatingInput" value={data.to} onChange={e => handleOnChange(e.target.value, "to")} placeholder="TO" />
                                <label htmlFor="floatingInput">TO</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-4">

                    </div>
                    <div className="col-8 row">
                        <div className="col-12">
                            <div className="form-floating mb-3">
                                <input type="text" autoComplete='OFF' className="form-control" id="floatingInput" value={data.company} onChange={e => handleOnChange(e.target.value, "company")} placeholder="Company Name" />
                                <label htmlFor="floatingInput">Company Name</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-4">

                    </div>
                    <div className="col-8 row">
                        <div className="col-12">
                            <div className="form-floating mb-3">
                                <input type="text" autoComplete='OFF' className="form-control" id="floatingInput" value={data.slogan} onChange={e => handleOnChange(e.target.value, "slogan")} placeholder="Slogan" />
                                <label htmlFor="floatingInput">Slogan</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-4">

                    </div>
                    <div className="col-8 row">
                        <div className="col-12">
                            <div className="form-floating mb-3">
                                <Editor placeholder="Bullets" value={data.bullets} onChange={e => handleOnChange(e.target.value, "bullets")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-4">

                    </div>
                    <div className="col-8 row">
                        <div className="container" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            {edit ? <button className="btn btn-secondary" onClick={cancelData}>C A N C E L</button> : ""}&nbsp;
                            {!edit && <button className="btn btn-success" onClick={ addData }>A D D&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E M P L O Y M E N T</button>}
                            {edit && <button className="btn btn-success" onClick={e => editData(idx)}>E D I T&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E M P L O Y M E N T</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
