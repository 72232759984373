import React, {  useContext, useState } from 'react'
import FormContext from "../../context/form/formContext"

export default function Certificate() {
    const initialData = {
        from: "",
        to: "",
        heading: "",
        description: ""
      }

    const formContext = useContext(FormContext)
    
    const [cdata, setCData] = useState(initialData)
    const [carr, setCArr] = useState([])

    const handleOnChange = (val, key)=>{
        setCData({...cdata, [key]:val})
    }

    const addData = () => {
        if (!cdata.heading) {
          alert("HEADING field is required");
        } else {
          setCArr([...carr, cdata]);
          setCData(initialData);
          formContext.setResume(prevResume => ({ ...prevResume, "certification": [...prevResume.certification, cdata] }));
        }
      }

    const onDeleteHandler = (index) => {
        const updatedData = [...carr];
        updatedData.splice(index, 1);
        setCArr(updatedData);
        formContext.setResume(prevResume => ({ ...prevResume, "certification": updatedData }));
    }

  return (
    <div>
        {/* Main Row */}
        <div className="row p-0 m-0">
            <div className="row p-0 m-0">
                <div className="col-4" style={{overflow: 'hidden'}}>
                    <ol className='p-0 m-0' style={{listStyleType:"+  "}}>
                        {carr && carr.map((val, index)=><li key={index}><nobr style={{fontSize:"12px"}}><span className="badge text-bg-danger p-2" onClick={() => onDeleteHandler(index)} style={{cursor:"pointer"}}><i className="bi bi-trash"></i></span>&nbsp;&nbsp;&nbsp;&nbsp;<strong style={{textOverflow:"ellipsis"}}>{val.heading} ({val.to})</strong></nobr></li>)}
                    </ol>
                    {(carr.length)?"":<>N O <br />D A T A <br />F O U N D</>}
                </div>
                <div className="col-8 row">
                    <div className="col-6">
                        <div className="form-floating mb-3">
                            <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={cdata.from} onChange={e=>handleOnChange(e.target.value, "from")}  placeholder="FROM" />
                            <label htmlFor="floatingInput">FROM</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-floating mb-3">
                            <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={cdata.to} onChange={e=>handleOnChange(e.target.value, "to")}  placeholder="TO" />
                            <label htmlFor="floatingInput">TO</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-0 m-0">
                <div className="col-4">

                </div>
                <div className="col-8 row">
                    <div className="col-12">
                        <div className="form-floating mb-3">
                            <input type="text" autoComplete='off' className="form-control" id="floatingInput"  value={cdata.heading} onChange={e=>handleOnChange(e.target.value, "heading")}  placeholder="Heading" />
                            <label htmlFor="floatingInput">Heading</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-0 m-0">
                <div className="col-4">

                </div>
                <div className="col-8 row">
                    <div className="col-12">
                        <div className="form-floating mb-3">
                            <textarea type="text" autoComplete='off' className="form-control" id="floatingInput"  value={cdata.description} onChange={e=>handleOnChange(e.target.value, "description")}  placeholder="Description" ></textarea>
                            <label htmlFor="floatingInput">Description</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-0 m-0">
                <div className="col-4">

                </div>
                <div className="col-8 row">
                    <div className="container" style={{textAlign:"right"}}>
                    <button className="btn btn-success" onClick={addData}>A D D&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;C E R T I F I C A T E S</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
