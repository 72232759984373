import React from 'react'

export default function DeviderForResume(props) {
  return (
    <div className="row m-0 p-0">
          <div className="col-4 p-0 m-0 ps-4" style={{backgroundColor: props.color}}>
            <div className="row text-light bg-secondary m-0 p-0" style={{height:"1px"}}></div>
          </div>
          <div className="col-8 p-0 m-0 pe-4" style={{backgroundColor:"#ffffff"}}>
            <div className="row text-light bg-secondary m-0 p-0" style={{height:"1px"}}></div>
          </div>
        </div>
  )
}
