import React, {  useContext, useState } from 'react'
import FormContext from "../../context/form/formContext"

export default function Educationfrom() {
    const initialData = {
        from: "",
        to: "",
        collegeName: "",
        shortDegree: "",
        largeDegree: "",
        percent: ""
      }

    const formContext = useContext(FormContext)
    
    const [edata, setEData] = useState(initialData)
    const [earr, setEArr] = useState([])

    const handleOnChange = (val, key)=>{
        setEData({...edata, [key]:val})
    }

    const addData = () => {
        if (!edata.shortDegree || !edata.largeDegree || !edata.collegeName) {
          alert("Degree & College name is required");
        } else {
          setEArr([...earr, edata]);
          setEData(initialData);
          formContext.setResume(prevResume => ({ ...prevResume, "education": [...prevResume.education, edata] }));
        }
      }

    const onDeleteHandler = (index) => {
        const updatedData = [...earr];
        updatedData.splice(index, 1);
        setEArr(updatedData);
        formContext.setResume(prevResume => ({ ...prevResume, "education": updatedData }));
    }

  return (
    <div>
        {/* Main Row */}
        <div className="row p-0 m-0">
            <div className="row p-0 m-0">
                <div className="col-4" style={{overflow: 'hidden'}}>
                    <ol className='p-0 m-0' style={{listStyleType:"+  "}}>
                        {earr && earr.map((val, index)=><li key={index}><nobr style={{fontSize:"12px"}}><span className="badge text-bg-danger p-2" onClick={() => onDeleteHandler(index)} style={{cursor:"pointer"}}><i className="bi bi-trash"></i></span>&nbsp;&nbsp;&nbsp;&nbsp;<strong style={{textOverflow:"ellipsis"}}><b style={{width:"10ch"}}>{ val.collegeName.slice(0, 10) + (val.collegeName.length > 10 ? "..." : "")}</b> ({val.shortDegree})</strong></nobr></li>)}
                    </ol>
                    {(earr.length)?"":<>N O <br />D A T A <br />F O U N D</>}
                </div>
                <div className="col-8 row">
                    <div className="col-4">
                        <div className="form-floating mb-3">
                            <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={edata.from} onChange={e=>handleOnChange(e.target.value, "from")}  placeholder="FROM" />
                            <label htmlFor="floatingInput">FROM</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-floating mb-3">
                            <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={edata.to} onChange={e=>handleOnChange(e.target.value, "to")}  placeholder="TO" />
                            <label htmlFor="floatingInput">TO</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-floating mb-3">
                            <input type="number" autoComplete='off' className="form-control" id="floatingInput" value={edata.percent} onChange={e=>handleOnChange(e.target.value, "percent")}  placeholder="Percent (%)" />
                            <label htmlFor="floatingInput">Percent (%)</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-0 m-0">
                <div className="col-4">

                </div>
                <div className="col-8 row">
                    <div className="col-6">
                        <div className="form-floating mb-3">
                            <input type="text" autoComplete='off' className="form-control" id="floatingInput"  value={edata.shortDegree} onChange={e=>handleOnChange(e.target.value, "shortDegree")}  placeholder="Degree name (short)" />
                            <label htmlFor="floatingInput">Degree name (short)</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-floating mb-3">
                            <input type="text" autoComplete='off' className="form-control" id="floatingInput"  value={edata.largeDegree} onChange={e=>handleOnChange(e.target.value, "largeDegree")}  placeholder="Degree name (Full)" />
                            <label htmlFor="floatingInput">Degree name (Full)</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-0 m-0">
                <div className="col-4">

                </div>
                <div className="col-8 row">
                    <div className="col-12">
                        <div className="form-floating mb-3">
                            <textarea type="text" autoComplete='off' className="form-control" id="floatingInput"  value={edata.collegeName} onChange={e=>handleOnChange(e.target.value, "collegeName")}  placeholder="Responsibities" ></textarea>
                            <label htmlFor="floatingInput">College Name</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-0 m-0">
                <div className="col-4">

                </div>
                <div className="col-8 row">
                    <div className="container" style={{textAlign:"right"}}>
                    <button className="btn btn-success" onClick={addData}>A D D&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E D U C A T I  O N</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
