import React, { useContext, useState } from 'react'
import FormContext from "../../context/form/formContext"

export default function Declare() {

    const formContext = useContext(FormContext)

    const initialData = {
        date:"",
        signature:"",
        place:"",
    }

    const [psdata, setPSData] = useState(initialData)

    const handleOnChange = (val, key) => {
        setPSData({ ...psdata, [key]: val })
        formContext.setResume({ ...formContext.resume, [key]: val })
    }

    return (
        <>
            <div className="row p-0 m-0">
                <div className="col-6">
                    <div className="form-floating mb-3">
                        <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={psdata.date} onChange={e => handleOnChange(e.target.value.toUpperCase(), "date")} placeholder="DATE" />
                        <label htmlFor="floatingInput">DATE</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating mb-3">
                        <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={psdata.place} onChange={e => handleOnChange(e.target.value.toUpperCase(), "place")} placeholder="PLACE" />
                        <label htmlFor="floatingInput">PLACE</label>
                    </div>
                </div>
            </div>
            <div className="row p-0 m-0">
                
                <div className="col-12">
                    <div className="form-floating mb-3">
                        <input className="form-control" autoComplete='off' id="floatingInput" value={psdata.signature} onChange={e => handleOnChange(e.target.value, "signature")} placeholder="SIGNATURE" />
                        <label htmlFor="floatingInput">SIGNATURE</label>
                    </div>
                </div>
            </div>
        </>
    )
}
