import React from 'react';
import './App.css';
import Home from './components/Home';
import FormState from './context/form/formState';

function App() {
  return (
    <>
    <FormState>
      <Home/>
    </FormState>
    </>
  );
}

export default App;
