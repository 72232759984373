import React from 'react'

export default function Navbar(props) {
  return (
    <nav className="navbar navbar-expand-lg mt-3" style={{backgroundColor: "#5AAFB6"}}>
        <div className="container-fluid">
          <b className="navbar-brand text-light" >R E S U M E&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;M A K E R</b>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              
            </ul>
            <div className="d-flex" role="search">
              <button className="btn btn-light" onClick={props.onClickDownload} type="submit">DOWNLOAD AS PDF</button>
            </div>
          </div>
        </div>
      </nav>
  )
}
