import React from 'react'
import Profile from './forms/Profile'
import Employement from './forms/Employement'
import Projects from './forms/Projects'
import Skills from './forms/Skills'
import Education from './forms/Educationform'
import Certificate from './forms/Certificate'
import Personal from './forms/Personal'
import Declare from './forms/Declare'
// import Settings from './forms/Settings'

export default function Accordian() {
  return (
    <div className="accordion mt-3" id="accordionExample">
      {/* <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S E T T I N G S
          </button>
        </h2>
        <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <Settings />
          </div>
        </div>
      </div> */}
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;U S E R&nbsp;&nbsp;&nbsp;P R O F I L E
          </button>
        </h2>
        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <Profile />
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E M P L O Y M E N T
          </button>
        </h2>
        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <Employement />
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P R O J E C T S
          </button>
        </h2>
        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <Projects />
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S K I L L S
          </button>
        </h2>
        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <Skills />
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E D U C A T I O N
          </button>
        </h2>
        <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <Education />
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;C E R T I F I C A T I O N
          </button>
        </h2>
        <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <Certificate />
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P E R S O N A L
          </button>
        </h2>
        <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <Personal />
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;D E C L A R E
          </button>
        </h2>
        <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <Declare />
          </div>
        </div>
      </div>
    </div>
  )
}
