import React, { useContext, useState } from 'react'
import FormContext from "../../context/form/formContext"
import Editor from 'react-simple-wysiwyg';

export default function Projects() {
    const initialData = {
        from: "",
        to: "",
        title: "",
        role: "",
        bullets: "",
        tech: ""
    }

    const roleListArry = ["Manager", "Chief Financial Officer", "Marketing Manager", "Sales Representative", "Customer Service Manager", "HR Coordinator", "Administrator", "Lead", "Chief Executive Officer", "Assistant Manager", "Chief Marketing Officer", "Accountant", "Executive", "Account Executive", "Bookkeeper", "President", "Chief Operating Officer", "Chief Technology Officer", "Project manager", "Business Analyst", "Finance Manager", "Administrative assistant", "Controller", "Coordinator", "Software engineer", "Trainee software engineer", "Senior software engineer", "Tech Lead", "Staff engineer", "Senio staff engineer", "Junio software engineer", "Junior staff engineer"]


    const formContext = useContext(FormContext)

    const [pdata, setPData] = useState(initialData)
    const [parr, setPArr] = useState([])
    const [edit, setEdit] = useState(false)
    const [idx, setIdx] = useState(0)

    const handleOnChange = (val, key) => {
        setPData({ ...pdata, [key]: val })
    }

    const addData = () => {
        if (!pdata.title || !pdata.role) {
            alert("TITLE and JOB ROLE fields are required");
        } else {
            setPArr([...parr, pdata]);
            setPData(initialData);
            formContext.setResume(prevResume => ({ ...prevResume, "project": [...prevResume.project, pdata] }));
        }
    }

    const onDeleteHandler = (index) => {
        if (edit && index === idx) {
            alert("This data is in editing mode, cannot delete Please save or cancel first.")
        } else {
            const updatedData = [...parr];
            updatedData.splice(index, 1);
            setPArr(updatedData);
            formContext.setResume(prevResume => ({ ...prevResume, "project": updatedData }));
        }
    }

    const cancelData = () => {
        setEdit(false)
        setPData(initialData)
    }

    const onUpdateHandler = (index) => {
        setEdit(true)
        setIdx(index)
        setPData(parr[index])
    }

    const editData = (index) => {
        if (!pdata.title || !pdata.role) {
            alert("TITLE and JOB ROLE fields are required");
        } else {
            const newArr = [...parr]; // Create a copy of the existing array
            newArr[index] = pdata; // Update the element at the specified index
            setPArr(newArr); // Set the updated array
            console.log(newArr);
            setPData(initialData);
            setEdit(false)
            formContext.setResume(prevResume => ({ ...prevResume, "project": newArr }));
        }
    }

    return (
        <div>
            {/* Main Row */}
            <div className="row p-0 m-0">
                <div className="row p-0 m-0">
                    <div className="col-4" style={{ overflow: 'hidden' }}>
                        <ol className='p-0 m-0' style={{ listStyleType: "+  " }}>
                            {parr && parr.map((val, index) => <li key={index}><nobr style={{ fontSize: "12px" }}><span className="badge text-bg-danger p-2" onClick={() => onDeleteHandler(index, idx)} style={{ cursor: "pointer" }}><i className="bi bi-trash"></i></span>&nbsp;<span className="badge text-bg-dark p-2" onClick={() => onUpdateHandler(idx)} style={{ cursor: "pointer" }}><i className="bi bi-pencil"></i></span>&nbsp;&nbsp;&nbsp;&nbsp;<strong style={{ textOverflow: "ellipsis" }}>{val.title} ({val.to})</strong></nobr></li>)}
                        </ol>
                        {(parr.length) ? "" : <>N O <br />D A T A <br />F O U N D</>}
                    </div>
                    <div className="col-8 row">
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={pdata.from} onChange={e => handleOnChange(e.target.value, "from")} placeholder="FROM" />
                                <label htmlFor="floatingInput">FROM</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={pdata.to} onChange={e => handleOnChange(e.target.value, "to")} placeholder="TO" />
                                <label htmlFor="floatingInput">TO</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-4">

                    </div>
                    <div className="col-8 row">
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={pdata.title} onChange={e => handleOnChange(e.target.value, "title")} placeholder="Title" />
                                <label htmlFor="floatingInput">Title</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" list='forRole' id="floatingInput" value={pdata.role} onChange={e => handleOnChange(e.target.value, "role")} placeholder="Job Role" />
                                <datalist id="forRole">
                                    {roleListArry.map((data, index) => {
                                        return (<option key={index} value={data}>{data}</option>)
                                    })}
                                </datalist>
                                <label htmlFor="floatingInput">Job Role</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-4">

                    </div>
                    <div className="col-8 row">
                        <div className="col-12">
                            <div className="form-floating mb-3">
                                <Editor placeholder="Responsibities" value={pdata.bullets} onChange={e => handleOnChange(e.target.value, "bullets")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-4">

                    </div>
                    <div className="col-8 row">
                        <div className="col-12">
                            <div className="form-floating mb-3">
                                <input type="text" autoComplete='off' className="form-control" id="floatingInput" value={pdata.tech} onChange={e => handleOnChange(e.target.value, "tech")} placeholder="Technologies" />
                                <label htmlFor="floatingInput">Technologies</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-4">

                    </div>
                    <div className="col-8 row">
                        <div className="container" style={{ textAlign: "right" }}>
                            {edit ? <button className="btn btn-secondary" onClick={cancelData}>C A N C E L</button> : ""}&nbsp;
                            {!edit && <button className="btn btn-success" onClick={addData}>A D D&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P R O J E C T</button>}
                            {edit && <button className="btn btn-success" onClick={e => editData(idx)}>E D I T&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P R O J E C T</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
