import React, { useState } from 'react'
import FromContext from "./formContext";

const FormState = (props) => {
    const initialData = {
        name: null,
        experience: null,
        role: null,
        roleType: null,
        employement: [],
        project: [],
        skills: [],
        skillsw: [],
        education: [],
        certification: [],
        father: null,
        sex: null,
        language: null,
        mobile: null,
        email: null,
        nationality: null,
        date: null,
        signature: null,
        place: null,
        REACT_APP_COLOR_NAME: process.env.REACT_APP_COLOR_NAME,
        REACT_APP_COLOR_HEADING: process.env.REACT_APP_COLOR_HEADING,
        REACT_APP_COLOR_OVER_ALL: process.env.REACT_APP_COLOR_OVER_ALL,
        REACT_APP_COLOR_OVER_SIDEBAR: process.env.REACT_APP_COLOR_OVER_SIDEBAR,
        REACT_APP_FONT_SIZE_NAME: process.env.REACT_APP_FONT_SIZE_NAME,
        REACT_APP_FONT_SIZE_HEADING: process.env.REACT_APP_FONT_SIZE_HEADING,
        REACT_APP_FONT_SIZE_OVER_ALL: process.env.REACT_APP_FONT_SIZE_OVER_ALL
    }

    const [resume, setResume] = useState(initialData)

    return (
        <FromContext.Provider value={{ resume, setResume }}>
            {props.children}
        </FromContext.Provider>
    )
}

export default FormState